<template>
  <div>
    <SelectWithInfinite
      :textFloat="textFloat"
      :options="branchList"
      :textField="textField"
      :valueField="valueField"
      :multiple="false"
      :clearable="false"
      placeholder="Select Branch"
      :value="value"
      :isRequired="isRequired"
      @input="valueInput"
      :isValidate="isValidate"
      :v="v"
      rawValue
    />
    <!-- <MultiSelect
      :value="value"
      :isRequired="isRequired"
      @input="valueInput"
      :isValidate="isValidate"
      :v="v"
    /> -->
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect,
  },
  props: {
    value: {
      required: true,
    },
    v: {
      required: true,
    },
    isValidate: {
      required: false,
    },
    textFloat: {
      required: false,
    },
    textField: {
      required: false,
    },
    valueField: {
      required: false,
    },
    isRequired: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      branchList: [],
    };
  },
  created() {
    this.getBranchList();
  },
  methods: {
    valueInput(val) {
      this.$emit("input", val);
    },
    async getBranchList() {
      const res = await this.axios(`/branch/listAll`);
      if (res.data.result == 1) {
        this.branchList = res.data.detail;
      }
    },
  },
};
</script>

<style></style>
